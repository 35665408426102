(function ($) {

    $('.menu-link').on('click',function(e){
        e.preventDefault()
        if($('.menu-container').hasClass('active')){
            $('.menu-container').removeClass('active');
            $('body').removeClass('menu-active');
        }else{
            $('.menu-container').addClass('active');
            $('body').addClass('menu-active');
        }
    });

    $(document).mouseup(function (e){ // событие клика по веб-документу
        var menu = $(".menu-container.active nav"),
            btnOpen = $('header .menu-link');// тут указываем ID элемента
        if (!menu.is(e.target) && !btnOpen.is(e.target) // если клик был не по нашему блоку
            && menu.has(e.target).length === 0 && btnOpen.has(e.target).length === 0) { // и не по его дочерним элементам\
            menu.parents('.menu-container.active').removeClass('active'); // скрываем его
            menu.parents('body').removeClass('menu-active'); // скрываем его
        }
    });
    $('.menu-container .parent').on('click', function(e){
        e.stopPropagation();
        $(this).toggleClass('active');
        $(this).find('.parent.active').each(function(){
            $(this).removeClass('active');
        });
    })
    $('.menu-container .btn-close').on('click',function(e){
        e.stopPropagation();
        $(this).parents('.menu-container').removeClass('active');
        $('body').removeClass('menu-active');

    });

    $(window).resize(function(){
        if($(window).width() < 992){
            var vh = window.innerHeight * 0.01;
            // Then we set the value in the --vh custom property to the root of the documen
            $('header .menu-container, header .menu-container .menu-bg').css('--vh',vh+'px');

        }
    });
    if($(window).width() < 992){
        var vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the documen
        $('.header .menu-container, header .menu-container .menu-bg').css('--vh',vh+'px');

    }


    $('.hexagon-container').on('click', function(){
        $(this).parents('.hexagon-list').find('.hexagon-container.active').removeClass('active');
        $(this).addClass('active');
    });

    $('.container-tab').on('click', function(){
        $(this).parents('.container-tabs').find('.container-tab.active').removeClass('active');
        $(this).addClass('active');
    });


    $('.scheme-slider-new .slides').slick({
        dots: false,
        arrows: true,
        // adaptiveHeight: true,
        fade: false,
        // autoplay: true,
        mobileFirst: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        prevArrow: '.btn-scheme-prev',
        nextArrow: '.btn-scheme-next',
        responsive: [
            {
                breakpoint: 768,
                settings:{
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 1199,
                settings:{
                    slidesToShow: 3,
                }
            }
        ]
    });

    $('.estimate-slider-new .slides').slick({
        dots: false,
        arrows: false,
        // adaptiveHeight: true,
        fade: true,
        // autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
        autoplaySpeed: 2000,
        responsive: []
    }).on('afterChange', function(event, slick, currentSlide){
        $('.estimate-slider-counter').html( ('<span>0' + (currentSlide + 1)) + '</span><sup>/0' + slick.slideCount + '</sup>');
    });

    $('.estimate-slider-next').click(function () {
        $('.estimate-slider-new .slides').slick('slickNext');
    });


    $('.partners-slider .slides').slick({
        dots: false,
        arrows: true,
        fade: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        prevArrow: '.btn-partners-prev',
        nextArrow: '.btn-partners-next',
        responsive: [
            {
                breakpoint: 1399,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
    $('.sertificats-slider .slides').slick({
        dots: false,
        arrows: true,
        fade: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        prevArrow: '.btn-sertificats-prev',
        nextArrow: '.btn-sertificats-next',
        responsive: [
            {
                breakpoint: 1399,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
}(jQuery));